import React from 'react'
import Header from '../landingPage/Header'
import classes from '../../CSS/donation.module.css'

const CustomDonation = () => {
    return (
        <div>
            <Header></Header>
        </div>
    )
}

export default CustomDonation